import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';


const BookExtract = ({ title, extract }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "downloads" }}) {
        edges {
          node {
            publicURL
            name
            ext
          }
        }
      }
    }
  `)

  const currentTitleData = data.allFile.edges.filter(info => {
    return info.node.name === `${title}-Sample`;
  });

  let downloadEpubUrl;
  let downloadMobiUrl;
  let downloadPdfUrl;

  currentTitleData.map(info => {
    if (info.node.ext === '.epub') {
      downloadEpubUrl = info.node.publicURL;
    } else if (info.node.ext === '.mobi') {
      downloadMobiUrl = info.node.publicURL;
    } else if (info.node.ext === '.pdf') {
      downloadPdfUrl = info.node.publicURL;
    }
  });

  const toggleSampleSlider = (section) => {
    if (section === 'downloads') {
      const crossbar = document.querySelector('.crossbar1-dl');
      const downloadsContainer = document.querySelector('.download-icons-wrapper');
      crossbar.classList.toggle('change');
      downloadsContainer.classList.toggle('show');
    }
    else if (section === 'full-text') {
      const crossbar = document.querySelector('.crossbar1-ft');
      const fullTextVeil = document.querySelector('.full-text-veil');
      const fullTextVeilRevealed = document.querySelector('.full-text-veil.show');
      const fullTextWrapper = document.querySelector('.full-text-wrapper');
      const fullTextWrapperHeight = fullTextWrapper.getBoundingClientRect().height;
      crossbar.classList.toggle('change');

      if (fullTextVeilRevealed) {
        fullTextVeilRevealed.style.height = 0;
        fullTextVeil.classList.remove('show');
      } else {
        fullTextVeil.classList.add('show');
        const fullTextRevealed = document.querySelector('.full-text-veil.show');
        fullTextRevealed.style.height = fullTextWrapperHeight + 'px';
      }
    }
    else if (section === 'audio') {
      const crossbar = document.querySelector('.crossbar1-aud');
      const audioContainer = document.querySelector('.audio-icon-wrapper');
      crossbar.classList.toggle('change');
      audioContainer.classList.toggle('show');
    }
  }

  return (
    <section className="extracts hero dark">
      <h1 className="hero-title">Samples</h1>
      <div className="samples-content-wrapper">
        <div className="downloads-container">
          <h2 className="gray-bar downloads">Downloads</h2>
          <div onClick={() => toggleSampleSlider('downloads')} className="div-slider-toggle downloads">
            <div className="crossbar1-dl"></div>
            <div className="crossbar2-dl"></div>
          </div>
          <div className="download-icons-wrapper">
            <div>
              <a href={downloadEpubUrl} download>
                <svg
                  id="epub-icon"
                  className="download-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 146.98 218.72">
                  <title>DownloadEpub</title>
                  <g id="Layer_1" data-name="Layer 1">
                    <path
                      id="epub-icon-body"
                      d="M292.34,137.58V317.31c0,3.85-2.82,7-6.27,7H155.63c-3.45,0-6.27-3.15-6.27-7V116.59c0-3.85,2.82-7,6.27-7H267.29Z"
                      transform="translate(-147.1 -106.64)"
                      fill="#010511" />
                    <path
                      id="epub-icon-curled-edge"
                      d="M292.08,136.63v.42H269.77a4.12,4.12,0,0,1-3.83-4.34V108.64H267Z"
                      transform="translate(-147.1 -106.64)"
                      fill="#F5C322" // curled edge
                      stroke="#f5c322"
                      strokeMiterlimit="10"
                      strokeWidth="4px" />
                    <path
                      id="epub-icon-outline"
                      d="M292.07,136.63V316.36c0,3.85-2.82,7-6.26,7H155.36c-3.44,0-6.26-3.15-6.26-7V115.64c0-3.85,2.82-7,6.26-7H267Z"
                      transform="translate(-147.1 -106.64)"
                      fill="none"
                      stroke="#f5c322"
                      strokeMiterlimit="10"
                      strokeWidth="4px" />
                    <text
                      id="epub-icon-text"
                      transform="translate(13.87 118.63)"
                      fontSize="30px"
                      fill="#f5c322"
                      strokeMiterlimit="10"
                      fontStyle="italic"
                      fontWeight="800"
                      letterSpacing="2px"
                      fontFamily="aviano-future, sans-serif">epub</text>
                  </g>
                </svg>
              </a>
              <h4 className="file-type-text">.epub</h4>
            </div>
            <div>
              <a href={downloadMobiUrl} download>
                <svg
                  id="mobi-icon"
                  className="download-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 146.98 218.72">
                  <title>DownloadMobi</title>
                  <g id="Layer_1" data-name="Layer 1">
                    <path
                      id="mobi-icon-body"
                      d="M292.34,137.58V317.31c0,3.85-2.82,7-6.27,7H155.63c-3.45,0-6.27-3.15-6.27-7V116.59c0-3.85,2.82-7,6.27-7H267.29Z"
                      transform="translate(-147.1 -106.64)"
                      fill="#010511" />
                    <path
                      id="mobi-icon-curled-edge"
                      d="M292.08,136.63v.42H269.77a4.12,4.12,0,0,1-3.83-4.34V108.64H267Z"
                      transform="translate(-147.1 -106.64)"
                      fill="#F5C322" // curled edge
                      stroke="#f5c322"
                      strokeMiterlimit="10"
                      strokeWidth="4px" />
                    <path
                      id="mobi-icon-outline"
                      d="M292.07,136.63V316.36c0,3.85-2.82,7-6.26,7H155.36c-3.44,0-6.26-3.15-6.26-7V115.64c0-3.85,2.82-7,6.26-7H267Z"
                      transform="translate(-147.1 -106.64)"
                      fill="none"
                      stroke="#f5c322"
                      strokeMiterlimit="10"
                      strokeWidth="4px" />
                    <text
                      id="mobi-icon-text"
                      transform="translate(19.87 118.63)"
                      fontSize="30px"
                      fill="#f5c322"
                      strokeMiterlimit="10"
                      fontStyle="italic"
                      fontWeight="800"
                      letterSpacing="2px"
                      fontFamily="aviano-future, sans-serif">mobi</text>
                  </g>
                </svg>
              </a>
              <h4 className="file-type-text">.mobi</h4>
            </div>
            <div>
              <a href={downloadPdfUrl} download>
                <svg
                  id="pdf-icon"
                  className="download-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 146.98 218.72">
                  <title>DownloadPdf</title>
                  <g id="Layer_1" data-name="Layer 1">
                    <path
                      id="pdf-icon-body"
                      d="M292.34,137.58V317.31c0,3.85-2.82,7-6.27,7H155.63c-3.45,0-6.27-3.15-6.27-7V116.59c0-3.85,2.82-7,6.27-7H267.29Z"
                      transform="translate(-147.1 -106.64)"
                      fill="#010511" />
                    <path
                      id="pdf-icon-curled-edge"
                      d="M292.08,136.63v.42H269.77a4.12,4.12,0,0,1-3.83-4.34V108.64H267Z"
                      transform="translate(-147.1 -106.64)"
                      fill="#F5C322" // curled edge
                      stroke="#f5c322"
                      strokeMiterlimit="10"
                      strokeWidth="4px" />
                    <path
                      id="pdf-icon-outline"
                      d="M292.07,136.63V316.36c0,3.85-2.82,7-6.26,7H155.36c-3.44,0-6.26-3.15-6.26-7V115.64c0-3.85,2.82-7,6.26-7H267Z"
                      transform="translate(-147.1 -106.64)"
                      fill="none"
                      stroke="#f5c322"
                      strokeMiterlimit="10"
                      strokeWidth="4px" />
                    <text
                      id="pdf-icon-text"
                      transform="translate(28.87 118.63)"
                      fontSize="30px"
                      fill="#f5c322"
                      strokeMiterlimit="10"
                      fontStyle="italic"
                      fontWeight="800"
                      letterSpacing="2px"
                      fontFamily="aviano-future, sans-serif">pdf</text>
                  </g>
                </svg>
              </a>
              <h4 className="file-type-text">.pdf</h4>
            </div>
          </div>
        </div>

        {/* <div className="audio-container">
          <h2 className="gray-bar audio">Listen Here</h2>
          <div onClick={() => toggleSampleSlider('audio')} className="div-slider-toggle audio">
            <div className="crossbar1-aud"></div>
            <div className="crossbar2-aud"></div>
          </div>
          <div className="audio-icon-wrapper">
            <div>
              <h2>
                Clicking on the icon below will play an audio file.
                Please adjust your volume accordingly.
              </h2>
              <svg
                id="mp3-icon"
                className="download-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 146.98 218.72">
                <title>DownloadMp3</title>
                <g id="Layer_1" data-name="Layer 1">
                  <path
                    id="mp3-icon-body"
                    d="M292.34,137.58V317.31c0,3.85-2.82,7-6.27,7H155.63c-3.45,0-6.27-3.15-6.27-7V116.59c0-3.85,2.82-7,6.27-7H267.29Z"
                    transform="translate(-147.1 -106.64)"
                    fill="#010511" />
                  <path
                    id="mp3-icon-curled-edge"
                    d="M292.08,136.63v.42H269.77a4.12,4.12,0,0,1-3.83-4.34V108.64H267Z"
                    transform="translate(-147.1 -106.64)"
                    fill="#F5C322" // curled edge
                    stroke="#f5c322"
                    strokeMiterlimit="10"
                    strokeWidth="4px" />
                  <path
                    id="mp3-icon-outline"
                    d="M292.07,136.63V316.36c0,3.85-2.82,7-6.26,7H155.36c-3.44,0-6.26-3.15-6.26-7V115.64c0-3.85,2.82-7,6.26-7H267Z"
                    transform="translate(-147.1 -106.64)"
                    fill="none"
                    stroke="#f5c322"
                    strokeMiterlimit="10"
                    strokeWidth="4px" />
                  <text
                    id="mp3-icon-text"
                    transform="translate(28.87 118.63)"
                    fontSize="30px"
                    fill="#f5c322"
                    strokeMiterlimit="10"
                    fontStyle="italic"
                    fontWeight="800"
                    letterSpacing="2px"
                    fontFamily="aviano-future, sans-serif">mp3</text>
                </g>
              </svg>
              <h4 className="file-type-text">.mp3</h4>
            </div>
          </div>
        </div> */}

        <div className="read-here-container">
          <h1 className="gray-bar read-here">Read Here</h1>
          <div onClick={() => toggleSampleSlider('full-text')} className="div-slider-toggle full-text">
            <div className="crossbar1-ft"></div>
            <div className="crossbar2-ft"></div>
          </div>
          <div className="full-text-veil">
            <div className="full-text-wrapper" dangerouslySetInnerHTML={extract}></div>
          </div>
        </div>
      </div>
      <hr />
    </section>
  );
}

export default BookExtract;