import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css';
import { booksMeta } from '../lib/booksMeta';

const ReviewsList = ({ title }) => {
  const data = booksMeta[title];

  const renderReviews = (data) => {
    const removeLeadQuote = data.reviews.slice(1);
    return removeLeadQuote.map((review, k) => {
      return (
        <ScrollAnimation key={k} animateIn="fadeIn" duration={2}>
          <div
            className="review"
            dangerouslySetInnerHTML={review}
          />
        </ScrollAnimation>
      )
    });
  }

  return (
    <section className="reviews hero dark">
      <h4 className="hero-title">Writing Praise</h4>
      <div className="grid-container">
        {
          typeof window !== 'undefined' &&
          <div className="reviews__grid">
            {renderReviews(data)}
          </div>
        }
      </div>
    </section>

  )
}

export default ReviewsList;