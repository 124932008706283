import React from 'react';
import { booksMeta } from '../lib/booksMeta';

const LeadingQuote = ({ title }) => {
  const data = booksMeta[title];
  return (
    <section className="leading-quote intro">
      <div
        className="review"
        dangerouslySetInnerHTML={data.reviews.slice(0, 1).pop()}
      />
    </section>
  )
}

export default LeadingQuote;