import React from 'react';
import { Helmet } from 'react-helmet';
import { booksMeta } from '../lib/booksMeta';
import Head from '../components/head'
import Layout from '../components/layout';
import Hero from '../components/hero';
import LeadingQuote from '../components/leadingQuote';
import ReviewsList from '../components/reviewsList';
import BookExtract from '../components/bookExtract';


const Deceit = () => {
  const data = booksMeta.Deceit;
  const PAGE = 'Deceit';
  const baseSiteUrl = 'https://authorseanallen.com'

  return (
    <Layout>
      <Head title={PAGE} />
      <Helmet>
        <meta name="description"
          content="Title: Deceit,
          Series: The D-Evolution,
          Series number: Book 1,
          Author: Sean Allen,
          Publisher: Vintage Six Media,
          Genres: Space Opera, Fantasy, Science Fiction" />
        <link rel="canonical" href={`${baseSiteUrl}/${PAGE}`} />
      </Helmet>
      <LeadingQuote
        title={data.title}
      />
      <Hero
        title={data.title}
        image={data.image}
        imgPosition={data.imgPosition}
        textPosition={data.textPosition}
        blurb={data.blurb}
        releaseDate={data.releaseDate}
        nextBookName={data.nextBookName}
        nextBookImage={data.nextBookImage}
        nextInSeries={data.nextInSeries}
        amazonUSLink={data.amazonUSLink}
        amazonUKLink={data.amazonUKLink}
        appleLink={data.appleLink}
        koboLink={data.koboLink}
        bnHCLink={data.bnHCLink}
        container="deceit"
      />
      {/* <BuyBook title={data.title} /> */}
      <BookExtract
        title={data.title}
        extract={data.extract} />
      <ReviewsList title={data.title} />
    </Layout>
  )
}

export default Deceit;