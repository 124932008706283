import React from 'react';
import { Link } from 'gatsby';

const NextBook = ({
  nextInSeries,
  nextBookName,
  nextBookImage
}) => {
  const imgUrl = require(`../images/3DProducts/${nextBookImage}`);

  return (
    <React.Fragment>
      {
        nextInSeries &&
        <Link className="next-book-link" to={`/${nextBookName}`}>
          <section className="next-book-wrapper">
            <h3 className="next-book-header">What's next in the Series?</h3>
            <img src={imgUrl} alt={`${nextBookName} cover`} />
          </section>
        </Link>
      }

      {
        !nextInSeries &&
        <section className="next-book-wrapper">
          <img className="logo-brand" src={imgUrl} alt="Vintage six logo" />
        </section>
      }
    </React.Fragment>
  );
}

export default NextBook;