import React from 'react';

const BuyBookSmall = ({
  title,
  amazonUSLink,
  amazonUKLink,
  appleLink,
  bnLink,
  bnHCLink,
  koboLink
}) => {

  return (
    <section className="buy-book-small hero dark">
      <h3 className="hero-title">Buy {title}</h3>
      <h3 className="center-text available-formats">On paper or E-book</h3>
      <div className="content">
        <ul className="buy-links">
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={amazonUSLink}>Amazon.com</a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={amazonUKLink}>Amazon.co.uk</a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={appleLink}>Apple Books</a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={koboLink}>Kobo</a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={bnHCLink}>Barnes &amp; Noble</a>
          </li>
        </ul>
      </div>
      <hr className="mobile-hr hide" />
    </section>
  );

}

export default BuyBookSmall;