import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import CountDown from './countdown';
import BuyBookSmall from './buyBookSmall';
import NextBook from './nextBook';

const Hero = ({
  title,
  image,
  thumbImg,
  imgPosition,
  textPosition,
  blurb,
  available,
  releaseDate,
  container,
  nextInSeries,
  nextBookName,
  nextBookImage,
  amazonUSLink,
  amazonUKLink,
  appleLink,
  koboLink,
  bnLink,
  bnHCLink,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    loadImage();
  });

  const loadImage = () => {
    if (imageLoaded) return;

    const imageUrl = require(`../images/heros/${image}`);

    let imgContainer = document.querySelector(".image-container");

    if (!imgContainer) return;

    imgContainer.classList.add("loading");
    let preloadedCover = document.createElement("img");
    preloadedCover.src = imageUrl;
    preloadedCover.alt = `${title} Cover`;
    preloadedCover.style.width = '428px';
    preloadedCover.style.width = '645px';

    preloadedCover.addEventListener('load', (event) => {
      imgContainer.classList.remove("loading");
      imgContainer.appendChild(preloadedCover);
      preloadedCover = null;
    });

    setImageLoaded(true);
  }

  return (
    <section className="hero dark">
      <div className="content">
        <div className={`${imgPosition}-col`}>
          <Link to={`/${title}`}>
            <div className="image-container loading">
              {/* //* image dynamically inserted */}
            </div>
          </Link>
          {/* <CountDown
            releaseDate={releaseDate}
            container={container}
          /> */}
          <BuyBookSmall
            title={title}
            bnHCLink={bnHCLink}
            koboLink={koboLink}
            appleLink={appleLink}
            amazonUKLink={amazonUKLink}
            amazonUSLink={amazonUSLink} />
        </div>
        <div className={`${textPosition}-col`}>
          <div dangerouslySetInnerHTML={blurb} />
          <NextBook
            nextInSeries={nextInSeries}
            nextBookName={nextBookName}
            nextBookImage={nextBookImage} />
        </div>
      </div>
      <hr />
    </section>
  )
}

export default Hero;